import axios from 'axios';

interface NewHelpSupportMessage {
  subject: string;
  message: string;
}

interface HelpSupportReply {
  thread_id: number;
  message: string;
}

export interface DashboardData {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    avatarType: string;
    profilePicture: string | null;
  };
  currentProgram: {
    id: number;
    title: string;
    description: string;
    instructor_id: number;
    duration_weeks: number;
    start_date: string;
    end_date: string;
    created_at: string;
    updated_at: string;
    exerciseSets: Array<{
      id: number;
      title: string;
    }>;
    currentSet: {
      id: number;
      title: string;
      week_number?: number;
      videos: Array<{
        id: number;
        title: string;
        youtube_link: string;
        duration: number;
        thumbnail_url: string;
      }>;
    } | null;
  } | null;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getUserExercises = () => api.get('/api/user-app/exercises').then(response => response.data);
export const getCurrentProgram = () => api.get('/api/users/current-program');
export const getUserProgress = () => api.get('/api/users/progress');
export const getCurrentSet = () => api.get('/api/users/current-set');

export const getDashboardData = async (): Promise<DashboardData> => {
  const response = await api.get<DashboardData>('/api/user-app/dashboard');
  return response.data;
};

export const getUserMessages = () => api.get('/api/user-app/messages/user');
export const createUserMessage = (data: { recipientId: number, message: string }) => 
  api.post('/api/user-app/messages/user', data);

export const getHelpSupportMessages = () => api.get('/api/user-app/messages/support');
export const createHelpSupportMessage = (data: NewHelpSupportMessage | HelpSupportReply) => 
  api.post('/api/user-app/messages/support', data);

export const markMessageAsRead = (data: { messageId: number, messageType: 'user' | 'support' }) => 
  api.put('/api/user-app/messages/read', data);

export const getDefaultMessageRecipient = () => api.get('/api/user-app/messages/default-recipient');

export const resolveHelpSupportThread = (threadId: number) => 
  api.put(`/api/user-app/messages/support/${threadId}/resolve`);



api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response?.data || error.message);
    return Promise.reject(error);
  }
);

export const login = (credentials: { email: string; password: string }) => 
  api.post('/api/users/login', credentials);

export const googleLogin = (payload: { userInfo: any; access_token: string }) => 
  api.post('/api/users/google-login', payload);

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return api(originalRequest);
        }).catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        processQueue(new Error('No refresh token available'));
        isRefreshing = false;
        localStorage.removeItem('token');
        // Redirect to login or dispatch a logout action
        return Promise.reject(new Error('No refresh token available'));
      }

      try {
        const response = await api.post('/api/users/refresh-token', { refreshToken });
        const newToken = response.data.token;
        localStorage.setItem('token', newToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);

        processQueue(null, newToken);
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        // Redirect to login or dispatch a logout action
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default api;