import React from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { formatDistance, isValid, parseISO } from 'date-fns';

interface SubscriptionCardProps {
  program: {
    title: string;
    description: string;
    duration_weeks: number | null;
    start_date: string | null;
    end_date: string | null;
  };
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ program }) => {
  const formatDateDisplay = (dateString: string | null) => {
    if (!dateString) return 'Not set';
    const date = parseISO(dateString);
    return isValid(date) ? formatDistance(date, new Date(), { addSuffix: true }) : 'Invalid date';
  };

  return (
    <Card className="mb-8">
      <CardHeader>
        <h2 className="text-2xl font-semibold">Current Subscription</h2>
      </CardHeader>
      <CardContent>
        <h3 className="text-xl font-bold mb-2">{program.title}</h3>
        <p className="mb-4 text-sm">{program.description}</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div>
            <p className="text-sm opacity-75">Duration</p>
            <p className="font-semibold">
              {program.duration_weeks ? `${program.duration_weeks} weeks` : 'Not specified'}
            </p>
          </div>
          <div>
            <p className="text-sm opacity-75">Started</p>
            <p className="font-semibold">{formatDateDisplay(program.start_date)}</p>
          </div>
          <div>
            <p className="text-sm opacity-75">Ends</p>
            <p className="font-semibold">{formatDateDisplay(program.end_date)}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;