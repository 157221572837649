import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogClose } from '@/components/ui/dialog';
import { getUserExercises } from '../services/api';
import { Play, X, Maximize2, Minimize2, Clock, BarChart, Calendar } from 'lucide-react';

interface Video {
  id: number;
  title: string;
  description: string;
  youtube_link: string;
  duration: number;
  thumbnail_url: string;
  order_in_set: number | null;
}

interface ExerciseData {
  user_id: number;
  username: string;
  currentProgram: {
    id: number;
    title: string;
  };
  currentSet: {
    id: number;
    title: string;
    videos: Video[];
  } | null;
}

interface FullscreenElement extends HTMLElement {
  mozRequestFullScreen?: () => Promise<void>;
  webkitRequestFullscreen?: () => Promise<void>;
  msRequestFullscreen?: () => Promise<void>;
}

const Exercises: React.FC = () => {
  const [exerciseData, setExerciseData] = useState<ExerciseData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<{ id: string; title: string } | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const data = await getUserExercises();
        //console.log('Exercise data:', JSON.stringify(data, null, 2));
        setExerciseData(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch exercises');
        setLoading(false);
      }
    };
  
    fetchExercises();

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    const handleOrientationChange = () => {
      const portrait = window.matchMedia("(orientation: portrait)").matches;
      setIsPortrait(portrait);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    window.addEventListener('resize', handleOrientationChange);
    handleOrientationChange(); // Initial check

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  const isProgramActive = (): boolean => {
    return !!exerciseData?.currentSet;
  };

  const getYouTubeVideoId = (url: string): string | null => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const openVideoModal = (youtubeLink: string, videoTitle: string) => {
    const videoId = getYouTubeVideoId(youtubeLink);
    if (videoId) {
      setSelectedVideo({ id: videoId, title: videoTitle });
      setIsDialogOpen(true);
    } else {
      console.error('Invalid YouTube URL:', youtubeLink);
    }
  };

  const toggleFullscreen = async () => {
    if (!videoContainerRef.current) return;
  
    const elem = videoContainerRef.current as FullscreenElement;
    
    if (!isFullscreen) {
      try {
        if (elem.requestFullscreen) {
          await elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          await elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          await elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          await elem.msRequestFullscreen();
        }
  
        // Check if it's a mobile device and in portrait mode
        if (isPortrait && window.screen && window.screen.orientation) {
          try {
            await window.screen.orientation.lock('landscape');
          } catch (error) {
            console.error('Error locking orientation:', error);
          }
        }
  
        setIsFullscreen(true);
      } catch (error) {
        console.error('Error entering fullscreen:', error);
      }
    } else {
      try {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
          await (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
          await (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
          await (document as any).msExitFullscreen();
        }
  
        // Unlock the orientation when exiting fullscreen
        if (window.screen && window.screen.orientation) {
          try {
            await window.screen.orientation.unlock();
          } catch (error) {
            console.error('Error unlocking orientation:', error);
          }
        }
  
        setIsFullscreen(false);
      } catch (error) {
        console.error('Error exiting fullscreen:', error);
      }
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen">{error}</div>;
  }

  if (!exerciseData || !exerciseData.currentProgram) {
    return <div className="flex items-center justify-center h-screen">No exercise data available</div>;
  }

  const { currentProgram, currentSet } = exerciseData;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Your Exercises</h1>
      


        <div className="flex-grow mb-6">
        <span className="text-xl font-semibold">{currentProgram.title} - </span>

            {isProgramActive() ? (
              <span className="text-xl font-semibold">{currentSet?.title}</span>
            ) : (
              <span className="text-xl font-semibold">Program Completed!</span>
            )}
          
        </div>
      

      {isProgramActive() && currentSet && currentSet.videos.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-14">
        {currentSet.videos.map((video) => (
          <div 
            key={video.id} 
            className="bg-white shadow-lg hover:shadow-xl transition-all duration-300 rounded-lg overflow-hidden cursor-pointer transform hover:scale-105"
            onClick={() => openVideoModal(video.youtube_link, video.title)}
          >
            <div className="relative">
              <img 
                src={video.thumbnail_url} 
                alt={video.title} 
                className="w-full h-48 object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-30 transition-opacity duration-300 flex items-center justify-center">
                <Play className="text-white opacity-0 hover:opacity-100 transition-opacity duration-300" size={48} />
              </div>
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2 line-clamp-2">{video.title}</h3>
              <p className="text-sm text-gray-500">
              {video.description.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
              </p>
            </div>
          </div>
        ))}
      </div>
      ) : (
        <Card className="bg-white shadow-lg flex">
          <div className="p-4 flex items-center justify-center bg-gray-100">
            <Calendar className="w-12 h-12 text-primary" />
          </div>
          <div className="flex-grow">
            <CardHeader>
              <CardTitle>Program Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-6">Thank you for completing this program. We hope you enjoyed your fitness journey!</p>
              <Button className="w-full">
                <Clock className="mr-2 h-4 w-4" /> View Exercise History
              </Button>
            </CardContent>
          </div>
        </Card>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[90vw] sm:h-[90vh] p-0 flex flex-col">
          <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
          {selectedVideo && (
            <div ref={videoContainerRef} className="flex-grow flex flex-col items-center justify-center relative w-full h-full">
              <div className="relative w-full aspect-video">
                <iframe
                  src={`https://www.youtube.com/embed/${selectedVideo.id}?autoplay=1&modestbranding=1`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
                <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white p-4 z-10">
                  Exercise Video: {selectedVideo.title}
                </div>
              </div>
              <Button 
                className="absolute bottom-4 right-4" 
                onClick={toggleFullscreen}
              >
                {isFullscreen ? (
                  <>
                    <Minimize2 className="mr-2 h-4 w-4" /> Exit Full Screen
                  </>
                ) : (
                  <>
                    <Maximize2 className="mr-2 h-4 w-4" /> Full Screen
                  </>
                )}
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Exercises;