import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store/store';
import { forgotPassword, googleLogin } from '../../store/authSlice';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Alert } from '@/components/ui/alert';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsGoogleUser(false);
    setIsSubmitting(true);
    
    try {
      const result = await dispatch(forgotPassword(email)).unwrap();
      if (result.isGoogleUser) {
        setIsGoogleUser(true);
        setMessage(result.message);
      } else {
        setMessage('Password reset instructions have been sent to your email.');
      }
    } catch (err: any) {
      if (err.isGoogleUser) {
        setIsGoogleUser(true);
        setMessage(err.message);
      } else {
        setError(err.message || 'Failed to process request. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setEmail('');
    setMessage('');
    setError('');
    setIsGoogleUser(false);
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        });
        const userInfo = await response.json();
        
        await dispatch(googleLogin({ userInfo, access_token: tokenResponse.access_token })).unwrap();
        navigate('/dashboard');
      } catch (error: any) {
        setError(error.message || 'Google login failed');
      }
    },
    onError: () => setError('Google login failed'),
  });

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>
      {error && <Alert variant="destructive" className="mb-4">{error}</Alert>}
      {!isGoogleUser && message && (
        <Alert className="mb-4 bg-green-100 border border-green-400 text-green-700">{message}</Alert>
      )}
      {isGoogleUser && (
        <Alert className="mb-4 bg-blue-100 border border-blue-400 text-blue-700">
          <p>{message}</p>
        </Alert>
      )}
      {!isGoogleUser && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block mb-1">Email</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isSubmitting}
            />
          </div>
          <Button type="submit" className="w-full" disabled={isSubmitting}>
            {isSubmitting ? 'Processing...' : 'Reset Password'}
          </Button>
        </form>
      )}
      {isGoogleUser && (
        <Button onClick={() => googleLoginHandler()} className="w-full">
        Login with Google
      </Button>
      )}
    </div>
  );
};

export default ForgotPassword;