import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import authReducer from './authSlice';
import programReducer from './programSlice';
import progressReducer from './progressSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'token'] // only persist user and token
};

const programPersistConfig = {
  key: 'program',
  storage,
  whitelist: ['currentProgram'] // only persist currentProgram
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedProgramReducer = persistReducer(programPersistConfig, programReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    program: persistedProgramReducer,
    progress: progressReducer,
    // Add other reducers here as needed
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE'],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;