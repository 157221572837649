import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { getHelpSupportMessages, createHelpSupportMessage, markMessageAsRead, resolveHelpSupportThread } from '../../services/api';
import { cn } from "@/lib/utils";

interface SupportMessage {
    id: number;
    thread_id: number;
    thread_subject: string;
    user_id: number;
    admin_id: number | null;
    sender_role: 'user' | 'admin';
    subject: string;
    message: string;
    status: 'open' | 'in_progress' | 'closed';
    read: boolean;
    created_at: string;
    user_username: string;
    admin_username: string | null;
  }

  const HelpSupportMessages: React.FC = () => {
    const [messages, setMessages] = useState<SupportMessage[]>([]);
    const [newSubject, setNewSubject] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [selectedThreadId, setSelectedThreadId] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    const user = useSelector((state: RootState) => state.auth.user);
    const messagesEndRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      fetchMessages();
    }, []);
  
    useEffect(() => {
      scrollToBottom();
    }, [messages]);
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    const fetchMessages = async () => {
      try {
        const response = await getHelpSupportMessages();
        setMessages(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch support messages');
        setLoading(false);
      }
    };
  
    const handleSendMessage = async () => {
        if (!newMessage.trim() || !user) return;
        if (!selectedThreadId && !newSubject.trim()) return;
    
        try {
          setLoading(true);
          let response;
          if (selectedThreadId) {
            // Replying to an existing thread
            response = await createHelpSupportMessage({ thread_id: selectedThreadId, message: newMessage });
          } else {
            // Creating a new thread
            response = await createHelpSupportMessage({ subject: newSubject, message: newMessage });
          }
          console.log('Help & Support message sent:', response);
          setNewSubject('');
          setNewMessage('');
          setSelectedThreadId(null);
          await fetchMessages(); // Refresh messages after sending
        } catch (err) {
          console.error('Failed to send support message:', err);
          setError('Failed to send support message. Please try again.');
        } finally {
          setLoading(false);
        }
      };
  
    const handleMarkAsRead = async (messageId: number) => {
      try {
        await markMessageAsRead({ messageId, messageType: 'support' });
        setMessages(messages.map(msg => 
          msg.id === messageId ? { ...msg, read: true } : msg
        ));
      } catch (err) {
        setError('Failed to mark message as read');
      }
    };

    const handleResolveThread = async (threadId: number) => {
        try {
          setLoading(true);
          await resolveHelpSupportThread(threadId);
          await fetchMessages(); // Refresh messages after resolving
        } catch (err) {
          console.error('Failed to resolve thread:', err);
          setError('Failed to resolve thread. Please try again.');
        } finally {
          setLoading(false);
        }
      };
  
    const groupedMessages = messages.reduce((acc, message) => {
    if (!acc[message.thread_id]) {
        acc[message.thread_id] = [];
    }
    acc[message.thread_id].push(message);
    return acc;
    }, {} as Record<number, SupportMessage[]>);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!user) return <div>Please log in to view support messages</div>;
  
    return (
        <Card className="h-[calc(100vh-4rem)] flex flex-col">
          <CardHeader>
            <CardTitle>Help & Support</CardTitle>
          </CardHeader>
          <CardContent className="flex-grow overflow-y-auto flex flex-col space-y-4 p-4">
            {Object.entries(groupedMessages).map(([threadId, threadMessages]) => (
              <Card 
                key={threadId} 
                className={cn(
                  "cursor-pointer hover:bg-gray-100",
                  selectedThreadId === Number(threadId) && "ring-2 ring-blue-500"
                )}
                onClick={() => setSelectedThreadId(Number(threadId))}
              >
                <CardHeader>
                  <div className="flex justify-between items-center">
                    <CardTitle>{threadMessages[0].thread_subject}</CardTitle>
                    <p className="text-sm text-gray-500">Status: {threadMessages[0].status}</p>
                  </div>
                </CardHeader>
                <CardContent>
                  {threadMessages.map((message) => (
                    <div key={message.id} className="mb-2">
                      <p className="font-semibold">{message.sender_role === 'user' ? 'You' : message.admin_username || 'Admin'}</p>
                      <p>{message.message}</p>
                      <p className="text-xs text-gray-500">{new Date(message.created_at).toLocaleString()}</p>
                    </div>
                  ))}

                </CardContent>
              </Card>
            ))}
            <div ref={messagesEndRef} />
          </CardContent>
    
          <div className="p-4 border-t">
            <div className="space-y-2">
              {!selectedThreadId && (
                <Input
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                  placeholder="Subject"
                />
              )}
              <Textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={selectedThreadId ? "Type your reply..." : "Type your message..."}
              />
              <Button onClick={handleSendMessage}>
                {selectedThreadId ? "Send Reply" : "Create New Support Request"}
              </Button>
              {selectedThreadId && (
                <Button onClick={() => setSelectedThreadId(null)} variant="outline">
                  Cancel Reply
                </Button>
              )}
            </div>
          </div>
        </Card>
      );
    };
  
  export default HelpSupportMessages;