import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, BookOpen, User, MessageSquare, BadgeHelp, LogOut } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/authSlice';
import { AppDispatch } from '../../store/store';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleLogout = async () => {
    await dispatch(logout());
    navigate('/login');
  };

  const navItems = [
    { name: 'Dashboard', path: '/dashboard', icon: Home },
    { name: 'Exercises', path: '/exercises', icon: BookOpen },
    { name: 'Profile', path: '/profile', icon: User },
    { name: 'Messages', path: '/messages', icon: MessageSquare },
    { name: 'Support', path: '/help-support', icon: BadgeHelp },
  ];

  return (
    <div className="flex flex-col h-screen">
      {/* Side navigation for larger screens */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`${
                    location.pathname === item.path
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  } group flex items-center px-2 py-2 text-sm font-medium rounded-md`}
                >
                  <item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  {item.name}
                </Link>
              ))}
              <button
                onClick={handleLogout}
                className="w-full text-left text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
              >
                <LogOut className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                Logout
              </button>
            </nav>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          <div className="py-0">
            <div className="max-w-7xl mx-auto px-0 sm:px-0 md:px-0">
              {children}
            </div>
          </div>
        </main>
      </div>

      {/* Bottom navigation for mobile screens */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="flex justify-around">
          {navItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`${
                location.pathname === item.path
                  ? 'text-blue-600'
                  : 'text-gray-500 hover:text-gray-900'
              } group flex flex-col items-center px-0 py-2 text-xs font-medium`}
            >
              <item.icon className="h-6 w-6" aria-hidden="true" />
              <span className="mt-1">{item.name}</span>
            </Link>
          ))}
          <button
            onClick={handleLogout}
            className="text-gray-500 hover:text-gray-900 group flex flex-col items-center px-0 py-2 text-xs font-medium"
          >
            <LogOut className="h-6 w-6" aria-hidden="true" />
            <span className="mt-1">Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;