import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserProgress } from '../services/api';

export interface Progress {
  completedSets: number;
  totalSets: number;
  currentWeek: number;
  totalWeeks: number;
}

interface ProgressState {
  progress: Progress | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProgressState = {
  progress: null,
  loading: false,
  error: null,
};

export const fetchUserProgress = createAsyncThunk(
  'progress/fetchUserProgress',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserProgress();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch user progress');
    }
  }
);

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProgress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProgress.fulfilled, (state, action) => {
        state.loading = false;
        state.progress = action.payload;
      })
      .addCase(fetchUserProgress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch progress';
      });
  },
});

export default progressSlice.reducer;