import React, { useEffect, useState, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks/redux';
import { fetchUserProfile, updateProfile } from '../store/authSlice';
import { getDashboardData, DashboardData } from '../services/api';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Switch } from '@/components/ui/switch';
import { Lock } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { formatDistance, isValid } from 'date-fns';
import PasswordResetModal from '../components/profile/PasswordResetModal';
import SubscriptionCard from '../components/ui/SubscriptionCard';


const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user, loading, error } = useAppSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    avatar_type: 'initials',
  });
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardError, setDashboardError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFormData({
        email: user.email,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        avatar_type: user.avatar_type || 'initials',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const data = await getDashboardData();
        console.log('Dashboard data:', data);
        setDashboardData(data);
      } catch (err) {
        setDashboardError('Failed to fetch dashboard data');
        console.error(err);
      } finally {
        setDashboardLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleInputBlur = useCallback(async (field: keyof typeof formData) => {
    if (user && formData[field] !== user[field as keyof typeof user]) {
      try {
        const result = await dispatch(updateProfile({ [field]: formData[field] })).unwrap();
        // Update the local state with the returned data
        setFormData(prev => ({ ...prev, ...result.user }));
      } catch (error) {
        console.error(`Failed to update ${field}:`, error);
        // Revert to the original value if the update fails
        setFormData(prev => ({ ...prev, [field]: user[field as keyof typeof user] || '' }));
      }
    }
  }, [dispatch, formData, user]);

  const handleAvatarTypeChange = useCallback(async () => {
    const newAvatarType = formData.avatar_type === 'initials' ? 'icon' : 'initials';
    try {
      const result = await dispatch(updateProfile({ avatar_type: newAvatarType })).unwrap();
      // Update the local state with the returned data
      setFormData(prev => ({ ...prev, ...result.user }));
    } catch (error) {
      console.error('Failed to update avatar type:', error);
      // Revert to the original avatar type if the update fails
      setFormData(prev => ({ ...prev, avatar_type: formData.avatar_type }));
    }
  }, [dispatch, formData.avatar_type]);

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  const formatDateDisplay = (dateString: string) => {
    const date = new Date(dateString);
    return isValid(date) ? formatDistance(date, new Date(), { addSuffix: true }) : 'Invalid date';
  };

  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>No user data available</div>;

  return (
    <div className="container mx-auto px-4 py-8 mb-14">
      <h1 className="text-2xl font-bold mb-4">User Profile</h1>
      
      <Card className="mb-8">
        <CardHeader>
          <h2 className="text-xl font-semibold">Personal Information</h2>
        </CardHeader>
        <CardContent className="flex items-start">
          <div className="mr-8">
            <Avatar className="w-24 h-24 text-5xl text-sky-600">
              {user.profile_picture && <AvatarImage src={user.profile_picture} alt={user.email} />}
              <AvatarFallback>{formData.avatar_type === 'initials' ? getInitials(formData.first_name, formData.last_name) : '👤'}</AvatarFallback>
            </Avatar>
            <div className="mt-2 flex items-center">
              <span className="mr-2">Use Initials</span>
              <Switch checked={formData.avatar_type === 'initials'} onCheckedChange={handleAvatarTypeChange} />
            </div>
          </div>
          <div className="flex-grow">
            <p><strong>Email:</strong> {formData.email}</p>
            <div className="flex items-center my-2">
              <strong className="mr-2">First Name:</strong>
              <Input
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                onBlur={() => handleInputBlur('first_name')}
                className="max-w-xs"
              />
            </div>
            <div className="flex items-center my-2">
              <strong className="mr-2">Last Name:</strong>
              <Input
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                onBlur={() => handleInputBlur('last_name')}
                className="max-w-xs"
              />
            </div>
            <Button className="mt-4" variant="outline" onClick={() => setIsPasswordResetModalOpen(true)}>
              <Lock className="mr-2 h-4 w-4" /> Reset Password
            </Button>
          </div>
        </CardContent>
      </Card>
      
      {/* Subscription Status Card */}
      {dashboardData?.currentProgram && (
        <SubscriptionCard 
          program={dashboardData.currentProgram}
        />
      )}
      
      {/* Program Progress Card */}
      {dashboardData?.currentProgram?.currentSet && (
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Program Progress</h2>
          </CardHeader>
          <CardContent>
            <h3 className="text-lg font-semibold mb-2">Current Exercise Set: {dashboardData.currentProgram.currentSet.title}</h3>
            {dashboardData.currentProgram.currentSet.week_number && (
              <p className="text-sm text-gray-500 mb-4">Week: {dashboardData.currentProgram.currentSet.week_number}</p>
            )}
          </CardContent>
        </Card>
      )}
      {!dashboardData?.currentProgram && (
        <Card className="mb-8 bg-yellow-100 border-yellow-300">
          <CardContent className="text-center py-8">
            <p className="text-xl mb-4">No active program. Start your fitness journey today!</p>
            <Button>Browse Programs</Button>
          </CardContent>
        </Card>
      )}

      <PasswordResetModal 
        isOpen={isPasswordResetModalOpen} 
        onClose={() => setIsPasswordResetModalOpen(false)} 
      />
    </div>
  );
};

export default ProfilePage;