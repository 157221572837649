import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { verifyEmail } from '../../store/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { Alert } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';

const VerifyEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const verify = async () => {
      if (token) {
        try {
          await dispatch(verifyEmail(token)).unwrap();
          setStatus('success');
          setMessage('Your email has been successfully verified. You can now log in.');
        } catch (error) {
          setStatus('error');
          setMessage('Failed to verify email. The link may be invalid or expired.');
        }
      } else {
        setStatus('error');
        setMessage('Invalid verification link.');
      }
    };

    verify();
  }, [token, dispatch]);

  return (
    <div className="max-w-md mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
      {status === 'verifying' && <p>Verifying your email...</p>}
      {status === 'success' && (
        <Alert className="mb-4 bg-green-100 border border-green-400 text-green-700">
          {message}
        </Alert>
      )}
      {status === 'error' && (
        <Alert variant="destructive" className="mb-4">
          {message}
        </Alert>
      )}
      <Button asChild className="mt-4">
        <Link to="/login">Go to Login</Link>
      </Button>
    </div>
  );
};

export default VerifyEmail;