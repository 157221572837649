import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { format, isAfter } from 'date-fns';
import { BarChart, Calendar, PlayCircle } from 'lucide-react';
import { getDashboardData, DashboardData } from '../services/api';

const backgroundImages = [
  '/images/dashboard-bg-1.jpg',
  '/images/dashboard-bg-2.jpg',
  '/images/dashboard-bg-3.jpg',
];

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const data = await getDashboardData();
        setDashboardData(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch dashboard data');
        setLoading(false);
        console.error(err);
      }
    };

    fetchDashboardData();
    const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
    setBackgroundImage(randomImage);
  }, []);

  if (loading) return <div className="h-screen flex items-center justify-center">Loading...</div>;
  if (error) return <div className="h-screen flex items-center justify-center">Error: {error}</div>;
  if (!dashboardData || !dashboardData.currentProgram) return <div className="h-screen flex items-center justify-center">No program data available</div>;

  const { currentProgram } = dashboardData;

  const isProgramActive = isAfter(new Date(currentProgram.end_date), new Date());

  const formatDateDisplay = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd.MM.yyyy');
  };

  const getDaysRemaining = () => {
    const endDate = new Date(currentProgram.end_date);
    const daysRemaining = Math.ceil((endDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    return `${daysRemaining} days (${formatDateDisplay(currentProgram.end_date)})`;
  };

  return (
    <div className="flex flex-col h-screen">
      <div 
        className="h-1/3 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <div className="h-2/3 bg-gray-100 overflow-auto">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4 mb-14">
          <div className="space-y-2">
            {/* Program Overview Card */}
            <Card className="bg-white shadow-lg flex">
              <div className="p-4 flex items-center justify-center bg-gray-100">
                <BarChart className="w-12 h-12 text-primary" />
              </div>
              <div className="flex-grow">
                <CardHeader className="p-4 space-y-0 pb-0">
                  <h2 className="text-xl font-semibold">{currentProgram.title}</h2>
                </CardHeader>
                <CardContent className="p-4 pt-2">
                  <ul className="list-none p-0 text-xs">
                    {currentProgram.exerciseSets.map((set) => (
                      <li 
                        key={set.id} 
                        className={set.id === currentProgram.currentSet?.id ? 'font-bold p-0 pb-2' : 'p-0 pb-2'}
                      >
                        {set.id === currentProgram.currentSet?.id ? `This week: ${set.title}` : set.title}
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </div>
            </Card>

            {/* Current Exercise Set Card */}
            {currentProgram.currentSet && (
              <Card className="bg-white shadow-lg flex">
                <div className="p-4 flex items-center justify-center bg-gray-100">
                  <PlayCircle className="w-12 h-12 text-primary" />
                </div>
                <div className="flex-grow">
                  <CardHeader className="p-4 space-y-0 pb-0">
                    <h2 className="text-xl font-semibold">Current Exercise Set: {currentProgram.currentSet.title}</h2>
                  </CardHeader>
                  <CardContent className="p-4">
                    <ul className="list-none p-0 text-xs">
                      {currentProgram.currentSet.videos.map((video) => (
                        <li className="p-0 pb-2" key={video.id}>{video.title}</li>
                      ))}
                    </ul>
                    <Button className="mt-2" onClick={() => navigate('/exercises')}>Resume</Button>
                  </CardContent>
                </div>
              </Card>
            )}

            {/* Subscription Details Card */}
            <Card className="bg-white shadow-lg flex">
              <div className="p-4 flex items-center justify-center bg-gray-100">
                <Calendar className="w-12 h-12 text-primary" />
              </div>
              <div className="flex-grow">
                <CardHeader className="p-4 space-y-0 pb-0">
                  <h2 className="text-xl font-semibold">Subscription Details</h2>
                </CardHeader>
                <CardContent className="p-4 text-xs pt-2">
                  <p><strong>Duration:</strong> {currentProgram.duration_weeks} weeks</p>
                  <p><strong>Started:</strong> {formatDateDisplay(currentProgram.start_date)}</p>
                  <p><strong>Ends in:</strong> {getDaysRemaining()}</p>
                  {!isProgramActive && (
                    <div className="mt-4">
                      <p>Congratulations on completing your program!</p>
                      <Button onClick={() => navigate('/exercises')} className="mt-2">
                        View Exercise History
                      </Button>
                    </div>
                  )}
                </CardContent>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;