import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentProgram } from '../services/api';

export interface Video {
  id: number;
  title: string;
  youtube_link: string;
  duration: number;
  thumbnail_url: string;
  order_in_set: number;
}

export interface ExerciseSet {
  id: number;
  title: string;
  week_number?: number;
  videos?: Video[];
}

export interface Program {
  id: number;
  title: string;
  description: string;
  instructor_id: number;
  duration_weeks: number;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  exerciseSets: ExerciseSet[];
  currentSet: ExerciseSet | null;
}

interface ProgramState {
  currentProgram: Program | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProgramState = {
  currentProgram: null,
  loading: false,
  error: null,
};

export const fetchUserProgram = createAsyncThunk(
  'program/fetchUserProgram',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCurrentProgram();
      console.log('API response:', response.data);
      return response.data.currentProgram;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch program');
    }
  }
);

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProgram.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProgram.fulfilled, (state, action) => {
        state.loading = false;
        state.currentProgram = action.payload;
        console.log('Program stored in state:', state.currentProgram);
      })
      .addCase(fetchUserProgram.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        console.error('Error fetching program:', action.payload);
      });
  },
});

export default programSlice.reducer;