import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { getUserMessages, createUserMessage, markMessageAsRead, getDefaultMessageRecipient } from '../../services/api';

interface Message {
  id: number;
  user_id: number;
  instructor_id: number | null;
  admin_id: number | null;
  sender_role: 'user' | 'instructor' | 'admin';
  message: string;
  read: boolean;
  created_at: string;
  user_username: string;
  instructor_username: string | null;
  admin_username: string | null;
}

const UserInstructorMessages: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await getUserMessages();
      setMessages(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch messages');
      setLoading(false);
    }
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !user) return;
    
    let recipientId: number | null = null;

    if (user.role === 'user') {
      recipientId = await getDefaultRecipient();
    } else {
      recipientId = messages[0]?.user_id || null;
    }

    if (!recipientId) {
      setError('No recipient found. Unable to send message.');
      return;
    }

    try {
      await createUserMessage({ recipientId, message: newMessage });
      setNewMessage('');
      await fetchMessages(); // Wait for messages to be fetched
      scrollToBottom(); // Scroll to bottom after new message is added
    } catch (err) {
      setError('Failed to send message');
    }
  };


  const getDefaultRecipient = async (): Promise<number | null> => {
    try {
      const response = await getDefaultMessageRecipient();
      return response.data.recipientId;
    } catch (err) {
      console.error('Failed to get default recipient:', err);
      setError('Failed to find a recipient for your message.');
      return null;
    }
  };

  const handleMarkAsRead = async (messageId: number) => {
    try {
      await markMessageAsRead({ messageId, messageType: 'user' });
      setMessages(messages.map(msg => 
        msg.id === messageId ? { ...msg, read: true } : msg
      ));
    } catch (err) {
      setError('Failed to mark message as read');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!user) return <div>Please log in to view messages</div>;

  return (
    <Card className="h-[calc(100vh-4rem)] flex flex-col">
      <CardHeader>
        <CardTitle>Instructor Messages</CardTitle>
      </CardHeader>
      <CardContent className="flex-grow overflow-y-auto flex flex-col space-y-4 p-4">
        {messages.map((message) => (
          <div 
            key={message.id} 
            className={`max-w-[70%] p-3 rounded-lg ${
              message.sender_role === user.role 
                ? 'bg-blue-100 self-end rounded-br-none' 
                : 'bg-gray-100 self-start rounded-bl-none'
            }`}
          >
            <p className="font-semibold">
              {message.sender_role === user.role ? 'You' : 
               message.sender_role === 'user' ? message.user_username : 
               message.sender_role === 'instructor' ? message.instructor_username :
               message.admin_username}
            </p>
            <p>{message.message}</p>
            <p className="text-xs text-gray-500">{new Date(message.created_at).toLocaleString()}</p>
            {!message.read && message.sender_role !== user.role && (
              <Button onClick={() => handleMarkAsRead(message.id)} variant="outline" size="sm" className="mt-2">Mark as Read</Button>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </CardContent>
      <div className="p-4 border-t">
        <div className="flex space-x-2">
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <Button onClick={handleSendMessage}>Send</Button>
        </div>
      </div>
    </Card>
  );
};

export default UserInstructorMessages;