import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { AppDispatch } from '../../store/store';
import { login, googleLogin } from '../../store/authSlice';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Alert } from '@/components/ui/alert';
import logo from '../../assets/body-harmony-logo.png';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    try {
      await dispatch(login({ email, password })).unwrap();
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.message || 'Failed to log in. Please check your credentials.');
    }
  };

  const googleLoginHandler = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        });
        const userInfo = await response.json();
        
        await dispatch(googleLogin({ userInfo, access_token: tokenResponse.access_token })).unwrap();
        navigate('/dashboard');
      } catch (error: any) {
        setError(error.message || 'Google login failed');
      }
    },
    onError: () => setError('Google login failed'),
  });

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 px-12">
        <img className="mx-auto h-32 w-auto mb-8" src={logo} alt="Body Harmony Logo" />
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        {error && <Alert variant="destructive" className="mb-4">{error}</Alert>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block mb-1">Email</label>
            <Input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block mb-1">Password</label>
            <Input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <Button type="submit" className="w-full">Login</Button>
        </form>
        <div className="mt-4">
          <Button onClick={() => googleLoginHandler()} variant="outline" className="w-full">
            Login with Google
          </Button>
        </div>
        <div className="mt-4 text-center">
          <Link to="/register" className="text-blue-600 hover:underline">Don't have an account? Register here</Link>
        </div>
        <div className="mt-2 text-center">
          <Link to="/forgot-password" className="text-blue-600 hover:underline">Forgot your password?</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;